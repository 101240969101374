/*----------------------------------------------------
pagination
----------------------------------------------------*/
.pagination {
  margin: 75px 0 25px;
  display: flex;
  justify-content: center;
  .screen-reader-text {
    display: none;
  }
  .nav-links {
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    .page-numbers {
      margin: 0 5px;
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      color: $primary;
      border: 1px solid $primary;
      background-color: $white;
      text-decoration: none;
      &.current {
        color: $white;
        background-color: $primary;
      }
      &:hover {
        color: $white;
        background-color: $primary;
      }
    }
  }
}
