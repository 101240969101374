.digest {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template:
      "img cont"
      / 60% 1fr;
  }
  @include media-breakpoint-up(xl) {
    grid-template:
      "img cont"
      / 55% 1fr;
  }
  &.reverse{
    @include media-breakpoint-up(lg) {
      grid-template:
        "cont img"
        / 1fr 60%;
    }
    @include media-breakpoint-up(xl) {
      grid-template:
        "cont img"
        / 1fr 55%;
      .cont {
        margin-left: auto;
      }
    }
  }
  .img {
    grid-area: img;
    img {
      width: 100%;
      height: auto;
    }
  }
  .cont {
    grid-area: cont;
    position: relative;
    padding: 2rem 1rem;
    display: grid;
    grid-template:
      "title"
      "..." 15px
      "subtitle"
      "..." 30px
      "text"
      / 100%;
    @include media-breakpoint-up(sm) {
      padding: 3rem;
    }
    @include media-breakpoint-up(lg) {
      grid-template:
        "..." 1fr
        "title"
        "..." 30px
        "subtitle"
        "..." 40px
        "text"
        "..." 1fr
        / 100%;
    }
    .digest-title {
      grid-area: title;
      font-style: italic;
      font-size: 30px;
      line-height: 1.5;
      font-weight: bold;
      margin: 0;
      @include media-breakpoint-up(lg) {
        font-size: 39px;
        letter-spacing: .35em;
      }
      br {
      }
    }
    .digest-subtitle {
      grid-area: subtitle;
      font-size: 12px;
      font-weight: bold;
      font-style: italic;
      margin: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include media-breakpoint-up(lg) {
        font-size: 15px;
      }
      &::after{
        content: "";
        width: 60px;
        height: 2px;
        background-color: $body-color;
        margin-left: 10px;
      }
    }
    .digest-text {
      grid-area: text;
      line-height: 1.75;
      font-size: 13px;
      @include media-breakpoint-up(lg) {
        max-width: 270px;
      }
    }
    .bg {
      position: absolute;
      top: 15px;
      right: 15px;
      @include media-breakpoint-up(lg) {
        &.rt{
          top: -50px;
        }
        &.rb{
          top: auto;
          right: 15px;
          bottom: 0;
        }
        &.lt{
          top: 0;
          right: auto;
          left: -180px;
        }
      }
      img {
        height: 45px;
        width: auto;
        @include media-breakpoint-up(sm) {
          height: auto;
          width: 120px;
        }
        @include media-breakpoint-up(lg) {
          width: auto;
        }
      }
    }
  }
}
.digest-space{
  @include media-breakpoint-up(lg) {
    height: 150px;
  }
}
.digest-space-bottom{
  @include media-breakpoint-up(lg) {
    height: 75px;
  }
}
//gridDigest
.gridDigest{
  display: grid;
  grid-template:
    "grid01 grid01 grid01 grid01"
    "...... ...... ...... ......" 5px
    "grid05 grid05 ...... grid02"
    "grid05 grid05 ...... ......" 5px
    "grid05 grid05 ...... grid03"
    "...... ...... ...... ......" 5px
    "grid04 grid04 grid04 grid04"
    / 1fr   1fr    5px    1fr;
  @include media-breakpoint-up(sm) {
    grid-template:
      "grid01 grid01 grid01 grid01"
      "...... ...... ...... ......" 15px
      "grid05 grid05 ...... grid02"
      "grid05 grid05 ...... ......" 15px
      "grid05 grid05 ...... grid03"
      "...... ...... ...... ......" 15px
      "grid04 grid04 grid04 grid04"
      / 1fr   1fr    15px   1fr;
  }
  @include media-breakpoint-up(lg) {
    max-height: 90vh;
    grid-template:
      "grid01 grid01 grid01 ...... grid04 grid04 grid04" 1fr
      "grid01 grid01 grid01 ...... ...... ...... ......" 15px
      "grid01 grid01 grid01 ...... grid05 grid05 grid05" 1fr
      "...... ...... ...... ...... grid05 grid05 grid05" 15px
      "grid02 ...... grid03 ...... grid05 grid05 grid05" 1fr
      / 1fr   15px   1fr    15px   1fr    15px   1fr;
    &.reverse{
      grid-template:
        "grid04 grid04 grid04 ...... grid01 grid01 grid01" 1fr
        "...... ...... ...... ...... grid01 grid01 grid01" 15px
        "grid05 grid05 grid05 ...... grid01 grid01 grid01" 1fr
        "grid05 grid05 grid05 ...... ...... ...... ......" 15px
        "grid05 grid05 grid05 ...... grid02 ...... grid03" 1fr
        / 1fr   15px   1fr    15px   1fr    15px   1fr;
    }
  }
  @include media-breakpoint-up(xl) {
    &.reverse{
      .cont {
        margin-left: auto;
      }
    }
  }
  .grid01{
    grid-area: grid01;
  }
  .grid02{
    grid-area: grid02;
  }
  .grid03{
    grid-area: grid03;
  }
  .grid04{
    grid-area: grid04;
  }
  .grid05{
    grid-area: grid05;
  }
  .img{
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    grid-area: cont;
    position: relative;
    padding: 1.5rem;
    display: grid;
    grid-template:
      "title"
      "..." 15px
      "subtitle"
      "..." 30px
      "text"
      / 100%;
    @include media-breakpoint-up(sm) {
      padding: 2rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 2rem;
      grid-template:
        "..." 1fr
        "title"
        "..." 20px
        "subtitle"
        "..." 30px
        "text"
        "..." 1fr
        / 100%;
    }
    @include media-breakpoint-up(xl) {
      max-width: 60%;
    }
    .digest-title {
      grid-area: title;
      font-size: 30px;
      line-height: 1.5;
      font-weight: bold;
      font-style: italic;
      margin: 0;
      @include media-breakpoint-up(lg) {
        font-size: 39px;
      }
      br {
      }
    }
    .digest-subtitle {
      grid-area: subtitle;
      font-size: 12px;
      font-weight: bold;
      margin: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include media-breakpoint-up(lg) {
        font-size: 15px;
      }
      &::after{
        content: "";
        width: 60px;
        height: 2px;
        background-color: $body-color;
        margin-left: 10px;
      }
    }
    .digest-text {
      grid-area: text;
      line-height: 1.75;
    }
  }
}
.gridDigest-space{
  height: 60px;
  @include media-breakpoint-up(sm) {
    height: 90px;
  }
  @include media-breakpoint-up(lg) {
    height: 150px;
  }
}
.about-digest{
  max-width: 720px;
  margin: 0 auto;
  .digest-title {
    font-style: italic;
    font-size: 30px;
    line-height: 1.5;
    font-weight: bold;
    margin: 0;
    @include media-breakpoint-up(lg) {
      font-size: 39px;
      letter-spacing: .35em;
    }
    br {
    }
  }
  .digest-subtitle {
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
    @include media-breakpoint-up(lg) {
      font-size: 15px;
    }
    &::after{
      content: "";
      width: 60px;
      height: 2px;
      background-color: $body-color;
      margin-left: 10px;
    }
  }
  .img{
    margin-bottom: 36px;
  }
    .digest-text {
      line-height: 1.75;
    }
}