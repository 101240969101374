.page-title {
  text-align: center;
  font-family: $font_maru;
  font-weight: bold;
  color: $primary;
  font-size: 24px;
  margin-bottom: 3rem;
  @include media-breakpoint-up(lg) {
    font-size: 32px;
  }
}
