.roundedTable {
  border-radius: $border-radius;
  overflow: hidden;
  border: 2px solid $primary;
  font-family: $font_maru;
  table {
    width: 100%;
    tr {
      th {
        background-color: $primary;
        color: $white;
        border-right: 2px solid $white;
        border-bottom: 2px solid $white;
        font-weight: bold;
        &:last-child {
          border-right: 0;
        }
      }
      td {
        border-right: 2px solid $primary;
        border-bottom: 2px solid $primary;
        &:last-child {
          border-right: 0;
        }
      }
      th,
      td {
        padding: 15px 5px;
        &.nowrap {
          width: 1%;
          white-space: nowrap;
        }
      }
      &:last-child {
        th,
        td {
          border-bottom: 0;
        }
      }
    }
  }
}
