.newsCarousel {
  display: flex;
  align-items: stretch;
  font-family: $font_maru;
  font-size: 13px;
  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }
  .header {
    background-color: $activity;
    color: $white;
    font-weight: bold;
    padding: 15px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(sm) {
      min-width: 170px;
    }
  }
  .body {
    background-color: $white;
    flex: 1;
    padding: 15px 10px;
    @include media-breakpoint-up(sm) {
      padding: 15px 24px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        a {
          display: flex;
          flex-direction: column;
          gap: 0;
          color: $body-color;
          text-decoration: none;
          transition: $transition-base;
          @include media-breakpoint-up(sm) {
            flex-direction: row;
            gap: 1em;
          }
          .date {
          }
          .text {
          }
          &:hover {
            color: $activity;
          }
        }
      }
    }
  }
}
