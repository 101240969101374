#topHero {
  position: relative;
  @include media-breakpoint-up(lg) {
  }
}
.scroll {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  color: $white;
  z-index: 2;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  &::after {
    margin-top: 10px;
    width: 1px;
    height: 60px;
    content: "";
    background-color: $white;
    animation: scrollanime 1s ease-out infinite;
  }
}
.heroSlideSingle {
  z-index: 1;
  position: relative;
  .bg {
    position: relative;
    width: 100%;
    height: calc(100vh - #{$headerHeight});
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      height: calc(100vh - #{$headerHeightSM});
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    position: absolute;
    top: 50%;
    left: 6%;
    transform: translateY(-50%);
    @include media-breakpoint-up(sm) {
      top: 150px;
      bottom: auto;
      transform: translateY(0);
    }
    .catch {
      font-size: 30px;
      line-height: 1.5;
      color: $white;
      font-family: $font_min;
      font-weight: bold;
      letter-spacing: 0;
      @include media-breakpoint-up(xl) {
        font-size: 39px;
      }
    }
  }
}

@keyframes scrollanime {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(60px);
    opacity: 0;
  }
}
