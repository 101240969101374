.kome {
  font-size: 10px;
  padding-left: 1em;
  text-indent: -1em;
}
.lh-lg-responsive {
  line-height: 1.33 !important;
  @include media-breakpoint-up(sm) {
    line-height: 2 !important;
  }
}
