#heroArea {
  .contents {
    .inner {
      .pagetitle {
        .bandAnimation01,
        .bandAnimation02,
        .bandAnimation03{
          transform: scaleX(0);
          color: $body-color;
          transform-origin: left;
        }
      }
      .leftAnimation01{
        transform: translateX(10px);
        opacity: 0;
      }
      .leftAnimation02{
        transform: translateX(10px);
        opacity: 0;
      }
    }
  }
}
.zoomupAnimation[data-trigger]{
  transform: scale(0);
  opacity: 0;
  transform-origin: center center;
  transition: $transition-base;
  &.InView{
    opacity: 1;
    transform: scale(1);
  }
}
.leftAnimation[data-trigger]{
  transform: translateX(10px);
  opacity: 0;
  transition: $transition-base;
  &.InView{
    transform: translateX(0);
    opacity: 1;
  }
}
.rightAnimation[data-trigger]{
  transform: translateX(-10px);
  opacity: 0;
  transition: $transition-base;
  &.InView{
    transform: translateX(0);
    opacity: 1;
  }
}