@import "custom";
@import "~bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

html {
  font-size: 15px;
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
  visibility: hidden;
  &.wf-active {
    visibility: visible;
  }
}
[v-cloak] {
  display: none;
}
main {
  overflow: hidden;
}
img {
  max-width: 100%;
  height: auto;
}
.font_maru {
  font-family: $font_maru;
  font-weight: bold;
}
.btn {
  font-family: $font_maru;
  font-weight: bold;
}
@import "components/header";
@import "components/hero";
@import "components/heroActivity";
@import "components/layout";
@import "components/thumbnailLink";
@import "components/sectionheader";
@import "components/thirdHeader";
@import "components/topNews";
@import "components/newsCarousel";
@import "components/activity";
@import "components/menu";
@import "components/column";
@import "components/dataTable";
@import "components/categoryList";
@import "components/sidemenu";
@import "components/anchorLinks";
@import "components/centeringLabel";
@import "components/facilityCard";
@import "components/pageTitle";
@import "components/digest";
@import "components/pagination";
@import "components/cms";
@import "components/cmsPrivacy";
@import "components/cmsKiyaku";
@import "components/summary";
@import "components/faq";
@import "components/btn";
@import "components/ancBtns";
@import "components/leadCopy";
@import "components/table";
@import "components/roundedTable";
@import "components/textDescription";
@import "components/footer";
@import "components/animation";
@import "u";
