#footer {
  padding-top: 30px;
  border-top: 1px solid $primary;
  .inner {
    padding: 0 6vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    @include media-breakpoint-up(sm) {
    }
    .finfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      @include media-breakpoint-up(sm) {
      }
      .flogo {
        margin: 0;
        img {
          width: 150px;
          height: auto;
        }
      }
      .text {
        margin: 0;
        font-size: calc(14 / 390 * 100vw);
        font-family: $font_maru;
        text-align: center;
        @include media-breakpoint-up(sm) {
          font-size: 14px;
        }
      }
    }
    .fnav {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 2rem;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;
      @include media-breakpoint-up(lg) {
        flex-direction: column-reverse;
        align-items: center;
      }
      .gnav {
        font-size: min(calc(14 / 390 * 100vw), 14px);
        font-family: $font_maru;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        font-weight: bold;
        @include media-breakpoint-up(lg) {
          width: 100%;
          font-size: 14px;
          flex-direction: row;
          justify-content: center;
          gap: 0;
        }
        > li {
          > a {
            display: block;
            border-left: 1px solid $border-color;
          }
          &:last-child {
            > a {
              border-right: 1px solid $border-color;
            }
          }
        }
        li {
          @include media-breakpoint-up(lg) {
            flex: 1;
            text-align: center;
          }
          a {
            color: $body-color;
            text-decoration: none;
          }
          ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            font-weight: normal;
            opacity: 0.75;
            @include media-breakpoint-up(lg) {
              font-size: 12px;
              margin-top: 5px;
            }
          }
        }
      }
      .snslinks {
        margin: 0;
        padding: 0;
        list-style-type: none;
        flex-direction: column;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 30px;
        flex-basis: 30%;
        @include media-breakpoint-up(lg) {
          flex-basis: 100%;
          flex-direction: row;
          gap: 30px;
        }
        li {
          a {
            color: $body-color;
            text-decoration: none;
          }
        }
      }
    }
  }
  .footerbottom {
    position: relative;
    margin-top: 60px;
    .bg {
      position: relative;
      z-index: 1;
    }
    .copyright {
      position: absolute;
      z-index: 2;
      top: 5px;
      text-align: center;
      font-size: 10px;
      width: 100%;
      @include media-breakpoint-up(sm) {
        top: 10px;
      }
      @include media-breakpoint-up(lg) {
        top: 30px;
        font-size: 12px;
      }
    }
  }
}
