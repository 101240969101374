.thumbnailLink {
  display: block;
  color: $body-color;
  text-decoration: none;
  .img {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: $border-radius;
    margin-bottom: 10px;
    &::before {
      content: "";
      width: 100%;
      padding-top: 65.4%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      transition: $transition-base;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .date {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .cat {
    color: $white;
    margin-bottom: 10px;
    font-size: 12px;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: baseline;
      gap: 4px;
      li {
        background-color: rgba($primary, 0.6);
        padding: 0 5px;
        min-width: 77px;
        text-align: center;
      }
    }
  }
  .title {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
  }
  &:hover {
    color: $primary;
    .img {
      img {
        transform: scale(1.05);
      }
    }
  }
}
