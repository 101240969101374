.cmsKiyaku {
  > h3 {
    background-color: #ceeee4;
    padding: 5px 10px;
    margin-bottom: 1.5rem;
    font-size: 20px;
    font-weight: normal;
    font-family: $font_maru;
  }
  > ul {
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    list-style-type: none;
    > li {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      gap: 5px;
      &::before {
        content: "●";
        font-size: 70%;
        color: $primary;
      }
    }
  }
}
