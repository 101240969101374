.faq{
  max-width: 600px;
  margin: 0 auto 3rem;
  .q{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1rem;
    .icon{
      line-height: 1;
      font-size: 30px;
      font-weight: bold;
      margin-right: 10px;
      text-align: right;
      width: 35px;
    }
    .text{
      font-size: 13px;
      font-weight: bold;
      line-height: 1.5;
      margin: 0;
      padding: 6px 0;
    }
  }
  .a{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .icon{
      line-height: 1;
      font-size: 30px;
      font-weight: bold;
      margin-right: 10px;
      text-align: right;
      width: 35px;
    }
    .text{
      font-size: 13px;
      line-height: 1.5;
      margin: 0;
      padding: 6px 0;
    }
  }
}