.summary{
  .img{
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 1.25rem;
    &::before{
      content: "";
      width: 100%;
      padding-top: 75%;//高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;      
    }
  }
  .cont{
    font-weight: bold;
    font-size: 14px;
    line-height: 1.75;
    margin-bottom: 3rem;
  }
}