.activity {
  display: grid;
  grid-template:
    "img1 img2"
    "cont cont"
    / 50% 1fr;
  @include media-breakpoint-up(lg) {
    grid-template:
      "img1 cont img2" 250px
      / 1fr 478px 1fr;
  }
  .img1 {
    grid-area: img1;
    position: relative;
    z-index: 1;
    img {
    }
    @include media-breakpoint-up(lg) {
      width: 100%;
      height: 100%;
      border-radius: 0 $border-radius $border-radius 0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .cont {
    position: relative;
    z-index: 2;
    grid-area: cont;
    padding: 0 6vw;
    margin-top: -30px;
    @include media-breakpoint-up(lg) {
      padding: 0;
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .inner {
      background-color: rgb(242, 242, 234);
      padding: 30px 15px;
      border-radius: $border-radius;
      @include media-breakpoint-up(lg) {
        padding: 0 30px;
      }
      .title {
        font-size: 24px;
        line-height: 1.2;
        font-weight: normal;
        color: $primary;
        text-align: center;
        font-family: $font_maru;
        font-weight: bold;
        margin-bottom: 1rem;
        @include media-breakpoint-up(sm) {
          font-size: 27px;
        }
      }
      .text {
        font-size: 14px;
        line-height: 1.75;
        margin-bottom: 1rem;
        font-family: $font_maru;
        font-weight: bold;
        @include media-breakpoint-up(sm) {
          text-align: center;
          font-size: 15px;
        }
      }
    }
  }
  .img2 {
    grid-area: img2;
    position: relative;
    z-index: 1;
    img {
    }
    @include media-breakpoint-up(lg) {
      width: 100%;
      height: 100%;
      border-radius: $border-radius 0 0 $border-radius;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
