.anchorLinks {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  li {
    flex: 1;
    text-align: center;
    white-space: nowrap;
    padding: 5px;
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
      color: $primary;
      text-decoration: none;
      // background-color: $white;
      border: 1px solid $primary;
      &:hover {
        color: $white;
        background-color: $primary;
      }
    }
  }
}
