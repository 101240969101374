#topNews {
  padding: 30px 0;
  @include media-breakpoint-up(sm) {
    padding: 45px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 60px 0;
  }
}
.topNewsLink {
  background-color: $white;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 30px;
  color: $primary;
  text-decoration: none;
  transition: $transition-base;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  &:hover {
    background-color: $primary;
    color: $white;
  }
  .icon {
  }
}
