// .sectionHeader {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   background: url("../img/bg_sectionHeader.png") center repeat-x;
//   margin-bottom: 3rem;
//   .title {
//     background-color: #f2f2ea;
//     display: inline-block;
//     padding: 5px 30px;
//     font-family: $font_maru;
//     font-weight: bold;
//     font-size: 18px;
//     color: $primary;
//     margin: 0;
//     position: relative;
//     z-index: 2;
//     @include media-breakpoint-up(sm) {
//       font-size: 24px;
//     }
//   }
// }

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // background: url("../img/bg_sectionHeader.png") center repeat-x;
  margin-bottom: 1.5rem;
  @include media-breakpoint-up(sm) {
    margin-bottom: 3rem;
  }
  @include media-breakpoint-up(lg) {
  }
  .inner {
    display: inline-block;
    position: relative;
    z-index: 2;
    white-space: nowrap;
    position: relative;
    min-width: 250px;
    text-align: center;
    &::before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 6px;
      left: 6px;
      content: "";
      background-image: url("../img/sectionHeaderShadow@3x.png");
      background-size: 7px;
    }
    .title {
      background-color: $primary;
      padding: 12px 30px;
      font-family: $font_maru;
      font-weight: bold;
      font-size: 18px;
      color: $white;
      margin: 0;
      position: relative;
      z-index: 2;
    }
    @include media-breakpoint-up(sm) {
      .title {
        font-size: 23px;
      }
    }
    @include media-breakpoint-up(lg) {
      .title {
        margin: 0;
      }
    }
  }
}
