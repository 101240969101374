.btn-more {
  font-weight: bold;
  &.btn-outline-dark {
    background-color: $white;
    &:hover {
      background-color: $black;
    }
  }
}
.btn.innerIcon {
  padding-left: 1rem;
  padding-right: 1rem;
  .icon {
    margin-left: 1rem;
  }
}
