.thirdHeader {
  margin-bottom: 2rem;
  .title {
    text-align: center;
    font-family: $font_maru;
    color: $primary;
    margin: 0;
    font-size: 24px;
    @include media-breakpoint-up(sm) {
      font-size: 32px;
    }
  }
}
