$headerHeight: 80px;
$headerHeightSM: 100px;
#header {
  width: 100%;
  background-color: $white;
  position: sticky;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  transition: $transition-base;
  display: grid;
  grid-template:
    "... logo ...... cta ... " #{$headerHeight}
    / 15px auto 1fr auto #{$headerHeight};
  @include media-breakpoint-up(lg) {
    grid-template:
      "... logo ... gnav ... cta ... " #{$headerHeightSM}
      / 30px auto 1fr auto 1fr auto 30px;
  }
  .logo {
    position: relative;
    z-index: 2;
    grid-area: logo;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: $white;
    padding: 5px;
    transition: $transition-base;
    @include media-breakpoint-up(lg) {
      width: 160px;
      height: 160px;
      top: 20px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &.topScroll {
    @include media-breakpoint-up(lg) {
      grid-template:
        "... logo ... gnav ... cta ... " calc(#{$headerHeightSM} - 30px)
        / 30px auto 1fr auto 1fr auto 30px;
    }
    .logo {
      width: $headerHeight;
      height: $headerHeight;
      @include media-breakpoint-up(lg) {
        width: $headerHeightSM;
        height: $headerHeightSM;
        top: 0;
      }
    }
    .gnav {
      > ul {
        > li {
          .inner {
            top: calc(#{$headerHeightSM} - 30px);
          }
        }
      }
    }
    .cta {
      @include media-breakpoint-up(lg) {
        transform: scale(0.8);
      }
    }
  }
  .gnav {
    grid-area: gnav;
    display: none;
    position: relative;
    z-index: 1;
    @include media-breakpoint-up(lg) {
      display: flex;
      height: 100%;
      align-items: flex-end;
    }
    > ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      gap: 1.5em;
      display: flex;
      justify-content: center;
      font-family: $font_maru;
      font-weight: 600;
      font-size: min(calc(16 / 1280 * 100vw), 17px);
      > li {
        > a {
          color: $body-color;
          text-decoration: none;
          transition: $transition-base;
          display: block;
          padding: 20px 0;
          &:hover {
            color: $primary;
          }
        }
        .inner {
          position: fixed;
          top: $headerHeightSM;
          width: 100vw;
          left: 0;
          background-color: rgba($primary, 0.9);
          padding: 20px;
          display: none;
          transition: $transition-base;
          > ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 15px;
            > li {
              > a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                text-decoration: none;
                color: $white;
                .img {
                  position: relative;
                  width: 150px;
                  overflow: hidden;
                  &::before {
                    content: "";
                    width: 100%;
                    padding-top: calc(
                      100 / 150 * 100%
                    ); //高さの比率 ÷ 幅の比率 × 100
                    display: block;
                  }
                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: $transition-base;
                  }
                }
                .text {
                  font-size: min(calc(13 / 1280 * 100vw), 13px);
                  line-height: 1.5;
                  text-align: center;
                }
                &:hover {
                  .img {
                    img {
                      transform: scale(1.05);
                    }
                  }
                }
              }
            }
          }
        }
        &:hover {
          .inner {
            animation: gnavInnerOpen 1s ease;
            display: block;
          }
        }
      }
    }
  }
  .cta {
    grid-area: cta;
    height: 100%;
    display: flex;
    align-items: center;
    transition: $transition-base;
    .btn {
      font-size: min(calc(12 / 390 * 100vw), 12px);
      line-height: 1.4;
      font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic,
        sans-serif;
      background-color: #ff6f43;
      color: $white;
      border: 2px solid #ff6f43;
      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }
      &:hover {
        background-color: $white;
        color: #ff6f43;
        > svg {
          polyline {
            stroke: #ff6f43;
          }
          line {
            stroke: #ff6f43;
          }
        }
      }
      > svg {
        width: min(calc(20 / 390 * 100vw), 20px);
      }
    }
  }
}
@keyframes gnavInnerOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.toggleMenuToggle {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-fixed + 1;
  width: $headerHeight;
  height: $headerHeight;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-up(lg) {
    display: none;
  }
  .icon {
    font-size: 20px;
    line-height: 1;
  }
  .text {
    font-size: 12px;
    line-height: 1;
    font-weight: bold;
    margin-top: 5px;
  }
}
.toggleMenu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: $zindex-fixed;
  background-color: #e7e9e4;
  padding: 10px 6%;
  padding-top: 90px;
  .gnav {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-family: $font_maru;
    font-weight: 600;
    font-size: 15px;
    li {
      a {
        display: block;
        padding: 10px 0;
        color: $body-color;
        text-decoration: none;
        &:hover {
          color: $primary;
        }
        &.active {
          color: $primary;
        }
      }
      ul {
        padding-left: 1.5em;
        list-style-type: disc;
        opacity: 0.75;
        font-weight: normal;
        li {
          a {
            padding: 5px 0;
          }
        }
      }
    }
  }
  .snslinks {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    font-family: $font_maru;
    font-weight: 600;
    font-size: 30px;
    li {
      margin-right: 20px;
      a {
        color: $body-color;
        text-decoration: none;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
.rightFix {
  position: fixed;
  top: 150px;
  right: 0;
  z-index: $zindex-fixed;
  background-color: $white;
  padding: 15px;
  border-radius: 10px 0 0 10px;
  display: none;
  @include media-breakpoint-up(sm) {
    display: inline-block;
  }
  .snslinks {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-family: $font_maru;
    font-weight: 600;
    font-size: 24px;
    @include media-breakpoint-up(lg) {
      font-size: 30px;
    }
    li {
      margin: 5px 0;
      a {
        color: $primary;
        text-decoration: none;
        &:hover {
          color: $body-color;
        }
      }
    }
  }
}
