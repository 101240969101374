.cmsPrivacy {
  > h2 {
    font-size: 21px;
    font-weight: bold;
    font-family: $font_maru;
    line-height: 1.5;
    margin-bottom: 2rem;
    padding-bottom: 10px;
    border-bottom: 1px solid $primary;
  }
  > p {
    font-size: 14px;
    margin-bottom: 2rem;
    line-height: 1.5;
  }
  > ul,
  ol {
    padding-left: 1em;
    margin-bottom: 2rem;
  }
}
