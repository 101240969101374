.facilityCardWrap {
  display: flex;
  flex-direction: column;
  .facilityCard {
    margin-bottom: 30px;
    flex: 1;
  }
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-left: -15px;
    margin-right: -15px;
    .facilityCard {
      margin: 0 15px;
    }
  }
}
.facilityCard {
  background-color: $white;
  border-radius: $border-radius;
  .header {
    .icon {
      // height: 130px;
      // width: 100%;
      // height: 150px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // padding: 30px;
      // img {
      //   max-height: 100%;
      //   max-width: 100%;
      // }
      img {
        max-width: 100%;
        height: auto;
        border-radius: $border-radius $border-radius 0 0;
      }
    }
    .title {
      font-size: 21px;
      font-weight: bold;
      font-family: $font_maru;
      margin: 0;
      text-align: center;
    }
  }
  .body {
    padding: 10px 20px;
    @include media-breakpoint-up(sm) {
      padding: 20px;
    }
  }
}
