.dataTable {
  display: table;
  width: 100%;
  dl {
    display: table-row;
    dt {
      display: block;
      font-size: 16px;
      line-height: 1.5;
      font-weight: bold;
      color: $primary;
      padding-top: 15px;
      @include media-breakpoint-up(sm) {
        display: table-cell;
        border-bottom: 2px dotted $primary;
        padding: 15px 0;
        width: 1%;
        white-space: nowrap;
      }
    }
    dd {
      display: block;
      font-size: 16px;
      line-height: 1.5;
      border-bottom: 2px dotted $primary;
      padding: 15px 0;
      @include media-breakpoint-up(sm) {
        display: table-cell;
        padding-left: 30px;
      }
    }
    &:last-child {
      dt,
      dd {
        border-bottom: none;
      }
    }
  }
}
