// Custom.scss
// Option B: Include parts of Bootstrap

// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$font_maru: dnp-shuei-mgothic-std, sans-serif;
$font_min: "YuMincho", "Yu Mincho", serif;
$font-family-sans-serif: "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic,
  sans-serif;
$headings-font-weight: bold;
$green: #00583b;
$red: #93252a;
$teal: #00998d;
$primary: $green;
$activity: $teal;
$danger: $red;
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "activity": $activity,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);
$min-contrast-ratio: 2;
$body-color: #000;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 7,
  10: $spacer * 8,
);

$carousel-control-opacity: 0.75;
$carousel-control-hover-opacity: 1;
$carousel-indicator-width: 6px;
$carousel-indicator-height: 6px;
.carousel-indicators [data-bs-target] {
  border-radius: 50%;
}
$border-radius: 10px;
$border-radius-sm: 5px;
$border-radius-lg: 15px;
// Optional
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
