.heroActivity {
  position: relative;
  .titleImg {
    position: relative;
    z-index: 1;
    margin: 0;
    @include media-breakpoint-up(lg) {
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
  .bottomCont {
    position: relative;
    // padding: 15px 6vw;
    @include media-breakpoint-up(sm) {
      padding: 15px 6vw;
      position: absolute;
      z-index: 2;
      width: 100%;
      bottom: 0;
      left: 0;
    }
    @include media-breakpoint-up(lg) {
      padding: 30px 6vw;
    }
  }
}
