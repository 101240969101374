.sidemenu {
  border-top: 1px solid $primary;
  border-bottom: 1px solid $primary;
  .sidemenuTitle {
    font-size: 15px;
    font-weight: bold;
    font-family: $font_maru;
    text-align: center;
    padding: 10px;
    line-height: 1.25;
    border-bottom: 1px dotted $primary;
    color: $primary;
    margin: 0;
  }
}
.sidemenuList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 12px;
  line-height: 1.25;
  li {
    border-bottom: 1px dotted $primary;
    &:last-child {
      border-bottom: none;
    }
    a {
      display: block;
      color: $primary;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
}
