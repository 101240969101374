.categoryList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: $font_maru;
  line-height: 1.25;
  li {
    margin-bottom: 10px;
    a {
      display: block;
      background-color: $white;
      color: $primary;
      border: 2px solid;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
}
