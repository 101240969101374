.brandContainer {
  padding: 0 6%;
}
.bandSpace {
  height: 60px;
  @include media-breakpoint-up(sm) {
    height: 90px;
  }
  @include media-breakpoint-up(lg) {
    height: 120px;
  }
}
.contentsContainer {
  padding: 0 6vw;
  max-width: calc(800px + 12vw);
  margin: 0 auto;
}
.widelayout {
  padding: 30px 0;
  @include media-breakpoint-up(sm) {
    padding: 45px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 60px 0;
  }
}
.archivelayout {
  display: flex;
  flex-direction: column;
  .maincont {
    margin-bottom: 60px;
  }
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: space-between;
    .maincont {
      // width: 600px;
      flex: 1;
      margin-bottom: 0;
    }
    .sidecont {
      width: 200px;
      margin-left: 60px;
    }
  }
}
