.cmslayout {
  max-width: 800px;
  margin: 0 auto;
}
.post-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
  .cont {
    flex: 1;
    .post-categories {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      font-size: 12px;
      margin-bottom: 1.5rem;
      li {
        margin-right: 10px;
        a {
          color: $primary;
          text-decoration: none;
        }
      }
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 1.5rem;
      line-height: 1.5;
    }
    .data {
      font-size: 12px;
      color: $gray-600;
      .date {
        margin: 0;
      }
    }
  }
  .sharebtn {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
    }
    li {
      margin-right: 10px;
      @include media-breakpoint-up(sm) {
        margin-right: 0;
        margin-left: 10px;
      }
      a {
        img {
          height: 25px;
        }
      }
    }
  }
}
.cms {
  $cms-space: 30px;
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: $cms-space;
  }
  h2 {
    background-color: $primary;
    color: $white;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
  }
  h3 {
    background-color: $white;
    color: $primary;
    border: 2px solid;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
  }
  h4 {
    color: $primary;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px solid;
    border-left: 2px solid;
  }
  p.lead {
    margin-bottom: $cms-space;
  }
  p:not([class]) {
    line-height: 1.75;
    margin-bottom: $cms-space;
  }
  .wp-block-image,
  .note-block,
  .wp-block-columns {
    margin-bottom: $cms-space;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}
