.menu-header {
  display: flex;
  justify-content: center;
  align-items: center;
  .logoimg {
    img {
      max-width: 100%;
      height: auto;
      @include media-breakpoint-up(sm) {
        max-width: 550px;
      }
    }
  }
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 100%;
    @include media-breakpoint-up(sm) {
      display: inline-flex;
      flex-direction: row;
      width: auto;
    }
    .bg {
      position: absolute;
      bottom: 0;
      right: -50px;
      height: 100%;
      z-index: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      img {
        max-height: 50%;
      }
    }
    .logo {
      z-index: 2;
      position: relative;
      text-align: center;
      img {
        height: 150px;
        width: auto;
        @include media-breakpoint-up(sm) {
          height: 180px;
        }
        @include media-breakpoint-up(lg) {
          height: 250px;
        }
      }
    }
    .text {
      flex: 1;
      z-index: 2;
      position: relative;
      font-family: $font_min;
      font-weight: bold;
      margin-top: 1rem;
      @include media-breakpoint-up(sm) {
        margin-left: 40px;
      }
      .year {
        font-size: 15px;
        margin: 0;
        line-height: 1.5;
        text-align: center;
        @include media-breakpoint-up(sm) {
          font-size: 18px;
          text-align: left;
        }
        @include media-breakpoint-up(lg) {
          font-size: 24px;
        }
      }
      .title {
        font-size: 30px;
        margin: 0;
        line-height: 1.25;
        letter-spacing: 0.1em;
        text-align: center;
        @include media-breakpoint-up(sm) {
          font-size: 36px;
          text-align: left;
        }
        @include media-breakpoint-up(lg) {
          font-size: 45px;
        }
      }
    }
  }
}
.menuSingle {
  display: grid;
  grid-template:
    "thumbnail"
    "..." 15px
    "cont"
    / 100%;
  @include media-breakpoint-up(sm) {
    grid-template:
      "thumbnail"
      "..." 20px
      "cont"
      / 100%;
  }
  @include media-breakpoint-up(lg) {
    grid-template:
      "thumbnail"
      "..." 30px
      "cont"
      / 100%;
  }
  &.wide {
    // @include media-breakpoint-up(sm) {
    //   grid-template:
    //     "thumbnail ... cont"
    //     / 50% 30px 1fr;
    // }
    @include media-breakpoint-up(lg) {
      grid-template:
        "thumbnail ... cont"
        / 60% 30px 1fr;
    }
  }
  .thumbnail {
    grid-area: thumbnail;
    .img {
      position: relative;
      width: 100%;
      overflow: hidden;
      border-radius: $border-radius;
      &::before {
        content: "";
        width: 100%;
        padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .cont {
    grid-area: cont;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: 21px;
      font-family: $font_min;
      font-weight: bold;
      @include media-breakpoint-up(sm) {
        font-size: 27px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 36px;
      }
    }
    .price {
      font-size: 12px;
      @include media-breakpoint-up(sm) {
        font-size: 14px;
      }
    }
    .description {
      font-size: 13px;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
  }
}
.menuContents {
  padding: 10px;
  // margin: 0;
  border: 1px dashed $danger;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  list-style-type: none;
  li {
    color: $danger;
    margin-right: 1rem;
    &::before {
      content: "○";
    }
  }
}
