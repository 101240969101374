.centeringLabel {
  margin-bottom: 2rem;
  text-align: center;
  .title {
    font-size: 21px;
    letter-spacing: 0.1em;
    display: inline-block;
    background-color: $danger;
    color: $white;
    padding: 10px 20px;
    font-family: $font_min;
    font-weight: bold;
    box-shadow: 5px 5px 0 rgba($danger, 0.15);
    @include media-breakpoint-up(lg) {
      font-size: 24px;
    }
  }
  &.green {
    .title {
      background-color: $primary;
      box-shadow: 5px 5px 0 rgba($primary, 0.15);
    }
  }
}
