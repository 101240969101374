.ancBtns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 4px;
  max-width: 900px;
  margin: 0 auto;
  .item {
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $primary;
    background-color: $white;
    padding: 10px 5px;
    color: $primary;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    border-radius: $border-radius;
    font-family: $font_maru;
    font-weight: bold;
    transition: $transition-base;
    &:hover {
      background-color: $primary;
      color: $white;
    }
    &.cta {
      background-color: $activity;
      color: $white;
      border-color: $activity;
      &:hover {
        background-color: $primary;
      }
    }
  }
}
