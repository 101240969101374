.column {
  border: 1px dashed $danger;
  padding: 2rem;
  .columnTitle {
    font-size: 24px;
    font-family: $font_min;
    font-weight: bold;
    text-align: center;
    color: $danger;
    margin-bottom: 1.5rem;
  }
}
.gyoushaTable {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  dl {
    flex-basis: 50%;
    text-align: center;
    margin: 0;
    padding: 10px 5px;
    @include media-breakpoint-up(sm) {
      flex-basis: 33.333%;
    }
    @include media-breakpoint-up(lg) {
      flex-basis: 25%;
    }
    dt {
      font-size: 14px;
      font-weight: bold;
    }
    dd {
      font-size: 10px;
      @include media-breakpoint-up(lg) {
        font-size: 12px;
      }
    }
  }
}
